import $ from "jquery";
import staticfunc from "@/config/static/func";

let Statistics = {};
let eventId = []; // 用户埋点id  

// 初始化
Statistics.init = function (statsData) {
  var _this = this;

  $(document).on("mousedown", function (event) {
    var target = event.srcElement || event.target;
    var attr = target.attributes;

    // 递归向上收集点击元素标记
    while (true) {
      if (target.tagName == 'BODY' || !(attr = target.attributes)) {
        break;
      }
      if (attr['stats-mark']) {
        _this._getStaticData(attr, statsData);
      }
      try {
        target = target.parentNode;
      } catch (event) {
        break;
      }
    }
  });

}

// 获取单个埋点请求字段
Statistics._getStaticData = function (attr, statsData) {
  var _this = this;
  var statsMark = attr['stats-mark'].value;
  var statsId = attr['stats-id'] ? attr['stats-id'].value : "";
  var projectPage = $('.container').attr("stats-projectpage");
  var data = {};

  data.projectPage = projectPage;
  data.efsid = statsId;

  statsData.forEach(function (item, index) {
    if (item.statsMark === statsMark) {

      for (var k in item) {
        data[k] = item[k];
      }
      _this.__sendEvent(data);
    }
  })
}

// 发送请求
Statistics.__sendEvent = function (data) {

  eventId.push(data.eventId);

  if (eventId.length > 50) {
    eventId.shift();
  }

  var query = {};
  // 项目信息相关字段
  query.p = 14; // 项目管理 14:编辑器新版 
  query.pp = data.projectPage; // 项目页面
  query.ppa = data.projectArea; // 项目页面区域

  // 客户端信息相关字段
  query.sw = screen.width; // 屏幕宽
  query.sh = screen.height; // 屏幕高
  query.os = navigator.platform; // 操作系统
 
  // 浏览器信息相关字段
  query.pl = 1; // 平台  1.pc
  query.b = navigator.appCodeName; // 浏览器
  query.bv = navigator.appVersion; // 浏览器版本

  // 用户信息相关字段
  query.vid = staticfunc.getRandomString(); // 唯一访客id 前端生成一个UUID 存入cookie或者storage
  query.sid = staticfunc.getRandomString(); // 会话id 标记一次会话 前端生成 该id生存期较短
  query.il = staticfunc.isLogin() ? 1 : 0; // 是否登录 0未登录 1登录
  query.uid =staticfunc.isLogin() ? staticfunc.isLogin() : ''; // 已登录用户id

  // 浏览相关信息
  query.du = window.location.origin + window.location.pathname; // 当前页面的地址  
  query.dp = window.location.pathname; // 当前url的path  
  query.dr = document.referrer; // 页面referer  
  query.drh = document.referrer; // 页面referer的域  
  query.c = ''; // 来源渠道  
  query.ia = data.isAjax ? data.isAjax : '0'; // 当前请求或者事件是否是ajax请求

  // 事件信息
  query.et = data.eventType ? data.eventType : '1'; // 事件类型 1跳转 2浏览 非跳转ajax 3点击事件 4滚动下拉上拉事件 5填写表单事件
  query.ecaid = data.eventArea ? data.eventArea : ''; // 事件坐标位置区域id 
  query.eid = data.eventId ? data.eventId : ''; // 事件坐标位置区域id 如1.头图区域
  query.ea = data.eventName ? data.eventName : ''; // 事件别名
  query.efsid = data.efsid ? data.efsid : ''; // 事件作用id 比如浏览文章列表的页码

  query.eis = eventId.join(","); // 用,按顺序连接的集合 最长50个id 用户事件的路径
  query.eti = staticfunc.getHms(new Date()); // 事件发生时间 时:分:秒
  query.ed = staticfunc.getDate(new Date()); // 事件发生的日期 年-月-日
  query.t = Math.round(new Date / 1000); // 事件发生时间戳 标准秒数 非毫秒微秒

  // 邀请相关
  query.ii = data.isInvite ? data.isInvite : 0; // 是否触发邀请 1是0否    
  query.iuid = data.invitedId ? data.invitedId : ''; // inviter_user_id邀请人id
  query.biuid = data.beInvitedId ? data.beInvitedId : ''; // be_invited_user_id被邀请人id
  query.inpa = data.invitedParem ? encodeURIComponent(data.invitedParem) : ''; // 邀请参数 如有?，=等特殊符号则 urlencode


  //  发送img请求
  var img = new Image();
  var rand = '_click_' + Math.floor(2147483648 * Math.random()).toString(36);
  window[rand] = img;
  img.onload = img.onerror = img.onabort = function () {
    img.onload = img.onerror = img.onabort = null;
    img = window[rand] = null;
  };

  var conditions = [];

  for (var k in query) {
    conditions.push(k + "=" + query[k]);
  }

  img.src = "https://tj.365editor.com/track.gif?" + conditions.join('&');
}

//  默认行为触发
Statistics.defalutEvent = function (statsData) {
  this.__sendEvent(statsData);
}


export default Statistics;


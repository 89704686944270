import axios from "axios";
import Vue from "vue";
let production = process.env.NODE_ENV === "production";
// 请求错误处理
// 设置全局请求头携带token信息
let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
let cancelToken = axios.CancelToken;
// 版权站请求
let httpServer = axios.create({
  baseURL: production ? process.env.VUE_APP_BaseUrl.slice(0, -1) : "/api",
  timeout: 30000,
  withCredentials: true, //允许跨域携带cookie
  headers: {
    "Cache-Control": "no-cache",
  },
});

let requestFn = function (config) {
  config.cancelToken = new cancelToken(function executor(c) {
    //本次axios请求的配置添加cancelToken
    pending.push({
      url: axios.defaults.baseURL + config.url,
      f: c,
    });
  });
  let { method, data } = config;
  let UpperMethod = String(method).toLocaleUpperCase();
  if (UpperMethod === "GET" && data) {
    config.url += "?";
    for (let i in data) {
      config.url += `${i}=${data[i]}&`;
    }
    config.url = config.url.slice(0, config.url.length - 1);
  }
  return config;
};
let requestErrorFn = function (error) {
  return Promise.reject(error);
};

let responseFn = function (response) {
  let responseJson = response.data;
  if (typeof responseJson != "object") return response.data;
  responseJson.status = responseJson.code == "S" ? 1 : 0;
  if (responseJson.code == "F" && responseJson.msg == "用户未登录") {
    Vue.prototype.$message.error(responseJson.msg || "用户未登录");
    if (process.env.NODE_ENV === "development") {
      Vue.prototype.staticFunc.setCookie("UserId", "");
    }
    window.location.reload();
    return response.data;
  }
  let configData = {};
  if (response.config.method === "post" && response.config.data) {
    configData = JSON.parse(response.config.data);
  }
  // 如果在post请求 存在 form_validate 的时候， 那么就默认为form表单提交 input报错。
  if (!configData.form_validate) {
    let noErrorUrl = [
      "/api/weixin/info",
      "/api/weixin/login",
      "/sc/api/user/info",
    ];
    if (
      responseJson.status !== 1 &&
      responseJson.status !== "1" &&
      noErrorUrl.indexOf(response.config.url.split("?")[0]) == -1
    ) {
      let errorStr =
        responseJson.error ||
        responseJson.msg ||
        responseJson.message ||
        "请求失败";
      Vue.prototype.$message.error(errorStr);
    }
  }
  return response.data;
};

let responseErrorFn = function (err) {
  const checkServer = () =>
    Object.prototype.toString.call(global.process) === "[object process]";
  if (err.toString().indexOf("timeout") != -1 && !checkServer) {
    Vue.prototype.$message.error("请求超时，请稍后再试");
  } else if (err.toString().indexOf("Network") != -1 && !checkServer) {
    Vue.prototype.$message.error("连接错误，请检查网络");
  }
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        err.message = "请求错误";
        break;
      case 401:
        err.message = "未授权，请登录";
        break;
      case 403:
        err.message = "用户权限不足";
        break;
      case 404:
        err.message = `请求地址出错: ${err.response.config.url}`;
        break;

      case 408:
        err.message = "请求超时";
        break;

      case 500:
        err.message = "服务器开个小差，请稍后再试";
        break;

      case 501:
        err.message = "服务器开个小差，请稍后再试";
        break;
      case 502:
        err.message = "服务器开个小差，请稍后再试";
        break;
      case 503:
        err.message = "服务器开个小差，请稍后再试";
        break;

      case 504:
        err.message = "服务器开个小差，请稍后再试";
        break;

      case 505:
        err.message = "HTTP版本不受支持";
        break;

      default:
      // Vue.prototype.staticFunc.removeCookie('user_uuid')
      // err.message = '请求失败，请稍后再试'
    }
    Vue.prototype.$message.error(err.message);
  }
  return Promise.reject(err.message);
};
/**
 *  请求拦截器  需要携带token信息
 */
httpServer.interceptors.request.use(requestFn, requestErrorFn);

// 响应拦截器
httpServer.interceptors.response.use(responseFn, responseErrorFn);

export default {
  httpServer: httpServer,
};

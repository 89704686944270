import $ from "jquery";
Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};
Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};
/**
 *
 * @param {Array} data 需要扁平化的数组
 * @param {String} keys 需要扁平化的关键字
 * 目前这一块主要运用在路由判断权限， 如果有其他操作， 请进行确认
 */
const flatData = (data, keys) => {
  return data.reduce((acc, next) => {
    acc.push(next);
    if (next[keys] && next[keys].length) acc.push(...flatData(next[keys]));
    return acc;
  }, []);
};
const getCookie = function (key) {
  let arr,
    result,
    reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) {
    result = arr[2];
  }
  return result;
};
const getToken = function () {
  const auth = getCookie("authorization");
  let authorization = auth ? JSON.parse(decodeURIComponent(auth)) : "";
  return authorization ? authorization.access_token : "";
};
export default {
  isLogin() {
    return getCookie("UserId");
  },
  getCookie,
  getToken,
  setCookie(key, v) {
    let d = new Date();
    // 目前设置10分钟
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    document.cookie = `${key}=${v};expires=${d.toGMTString()};path=/;`;
  },
  setCookieToday(name, value) {
    var exp = new Date();
    exp.setDate(exp.getDate());
    exp.setHours(23);
    exp.setMinutes(59);
    exp.setSeconds(59);
    exp.setTime(exp.getTime());
    // var hostname = location.hostname.substring(
    //   location.hostname.indexOf('.') + 1
    // ) // 设置为一级域名
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
  },
  removeCookie(key) {
    let d = new Date(),
      v = this.getCookie(key);
    d.setTime(d.getTime() - 1000);
    if (v !== undefined) {
      this.setCookie(key, "", d);
    }
  },
  /**
   *
   * @param date
   * @param str
   * @returns {string}
   */
  getDate(date, str = "-") {
    let newD = new Date(date);
    let year = newD.getFullYear();
    let month =
      newD.getMonth() + 1 >= 10
        ? newD.getMonth() + 1
        : `0${newD.getMonth() + 1}`;
    let day = newD.getDate() >= 10 ? newD.getDate() : `0${newD.getDate()}`;
    return `${year + str + month + str + day}`;
  },
  getMin(date) {
    if (!date) {
      return "-";
    }
    return new Date(date).Format("yyyy-MM-dd hh:mm");
  },
  getSec(date) {
    if (!date) {
      return "-";
    }
    return new Date(date).Format("yyyy-MM-dd hh:mm:ss");
  },
  getMMddhhmm(date) {
    if (!date) {
      return "-";
    }
    return new Date(date).Format("MM-dd hh:mm");
  },
  getddhhmm(date) {
    if (!date) {
      return "-";
    }
    let nowDate = new Date().getTime();
    let newDate = new Date(
      new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    ).getTime();
    let sixHours = 6 * 60 * 60 * 1000;
    date = date + newDate - nowDate + sixHours;
    date = Number(date.toFixed(0));
    let Dates = new Date(date);
    let hours = Dates.getHours();
    let min = Dates.getMinutes();
    return `${hours}时${min}分`;
  },
  getHms(date) {
    if (!date) {
      return "-";
    }
    return new Date(date).Format("hh:mm:ss");
  },
  /**
   * 对日期格式（20191210）进行格式化
   */
  dayFormat(str) {
    if (parseInt(str)) {
      return `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6)}`;
    }
    return str;
  },

  downloadImg(imgSrc) {
    // iframe 下载
    console.log("iframe下载");

    let iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.height = 0;
    iframe.src = imgSrc;
    document.body.appendChild(iframe);
    /* try {
      fetch(imgSrc)
        .then((res) =>
          res.blob().then((blob) => {
            if (callback && typeof callback == 'function') {
              callback()
            }
            var dotIndex = imgSrc.lastIndexOf('.') + 1
            var fileTypeStr = imgSrc.split('?')[0].slice(dotIndex)
            var a = document.createElement('a')
            a.className = 'download_image'
            var url = window.URL.createObjectURL(blob)
            a.href = url
            a.download =
              `${name}.${fileTypeStr}` || `下载图片名称.${fileTypeStr}` // 设置图片名称
            var event = new MouseEvent('click') // 创建一个单击事件
            a.dispatchEvent(event) // 触发a的单击事件
            if (window.navigator.userAgent.indexOf('Edge') > -1) {
              window.URL.createObjectURL(url)
            } else {
              window.URL.revokeObjectURL(url)
            }
          })
        )
        .catch((err) => {
          window.location.href = imgSrc;
          //window.open(imgSrc)
          if (callback && typeof callback == 'function') {
            callback()
          }
        })
    } catch {
      window.location.href = imgSrc;
      //window.open(imgSrc)
      if (callback && typeof callback == 'function') {
        callback()
      }
    } */
  },
  downloadFile(url) {
    let frameDom = document.createElement("iframe");
    frameDom.style.display = "none";
    frameDom.src = url + "?response-content-type=application/octet-stream";
    document.body.appendChild(frameDom);
    setTimeout(function () {
      document.body.removeChild(frameDom);
    }, 1000);
  },
  async downloadFile11(url, fileName = "") {
    try {
      let blob = await downloadBlob(url, fileName);
      let newurl = URL.createObjectURL(blob);
      downloadURL(newurl, fileName);
      URL.revokeObjectURL(newurl);
    } catch (err) {
      throw new Error(err.message);
    }
    function downloadBlob(url) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error(xhr.statusText || "Download failed."));
          }
        };
        xhr.onerror = function () {
          reject(new Error("Download failed."));
        };
        xhr.send();
      });
    }
    function downloadURL(url, name = "") {
      let link = document.createElement("a");
      link.download = name;
      link.href = url;
      if ("download" in document.createElement("a")) {
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // 对不支持download进行兼容
        click(link, (link.target = "_blank"));
      }
    }
    function click(node) {
      try {
        node.dispatchEvent(new MouseEvent("click"));
      } catch (e) {
        var evt = document.createEvent("MouseEvents");
        evt.initMouseEvent(
          "click",
          true,
          true,
          window,
          0,
          0,
          0,
          80,
          20,
          false,
          false,
          false,
          false,
          0,
          null
        );
        node.dispatchEvent(evt);
      }
    }
  },
  // 防抖a
  debounce(func, threshold, execAsap) {
    var timeout;
    return function debounced() {
      var obj = this,
        args = arguments;
      function delayed() {
        if (!execAsap) func.apply(obj, args);
        timeout = null;
      }
      if (timeout) {
        clearTimeout(timeout);
      } else if (execAsap) {
        func.apply(obj, args);
      }
      timeout = setTimeout(delayed, threshold || 100);
    };
  },
  //+---------------------------------------------------
  //| 日期计算 - 增加
  //+---------------------------------------------------
  dateAdd(date, strInterval, Number) {
    var dtTmp = date ? new Date(date.replace(/-/g, "/")) : new Date();
    switch (strInterval) {
      case "s":
        return new Date(dtTmp.getTime() + 1000 * Number); // 秒
      case "n":
        return new Date(dtTmp.getTime() + 60000 * Number); // 分
      case "h":
        return new Date(dtTmp.getTime() + 3600000 * Number); // 时
      case "d":
        return new Date(dtTmp.getTime() + 86400000 * Number); // 天
      case "w":
        return new Date(dtTmp.getTime() + 86400000 * 7 * Number); // 周
      case "q":
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() + Number * 3,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 季
      case "m":
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() + Number,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 月
      case "y":
        return new Date(
          dtTmp.getFullYear() + Number,
          dtTmp.getMonth(),
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 年
    }
  },

  //+---------------------------------------------------
  //| 日期计算 - 减去
  //+---------------------------------------------------
  dateMinus(date, strInterval, Number) {
    var dtTmp = date ? new Date(date.replace(/-/g, "/")) : new Date();
    switch (strInterval) {
      case "s":
        return new Date(dtTmp.getTime() - 1000 * Number); // 秒
      case "n":
        return new Date(dtTmp.getTime() - 60000 * Number); // 分
      case "h":
        return new Date(dtTmp.getTime() - 3600000 * Number); // 时
      case "d":
        return new Date(dtTmp.getTime() - 86400000 * Number); // 天
      case "w":
        return new Date(dtTmp.getTime() - 86400000 * 7 * Number); // 周
      case "q":
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() - Number * 3,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 季
      case "m":
        return new Date(
          dtTmp.getFullYear(),
          dtTmp.getMonth() - Number,
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 月
      case "y":
        return new Date(
          dtTmp.getFullYear() - Number,
          dtTmp.getMonth(),
          dtTmp.getDate(),
          dtTmp.getHours(),
          dtTmp.getMinutes(),
          dtTmp.getSeconds()
        ); // 年
    }
  },
  /*
   ** 用途：检查输入邮箱地址是否正确
   ** 输入：strMobile：字符串
   ** 返回：如果通过验证返回true,否则返回false
   */
  checkEmail(strEmail) {
    var emailReg =
      /^([A-Za-z0-9]*[-_\.]?[A-Za-z0-9]+)*[A-Za-z0-9\._-]@[a-zA-Z\d]+\.[a-zA-Z]{1,5}$/;
    if (emailReg.test(strEmail)) {
      return true;
    } else {
      return false;
    }
  },
  /*
   ** 用途：检查输入手机号码是否正确
   ** 输入：strMobile：字符串
   ** 返回：如果通过验证返回true,否则返回false
   */
  checkMobile: function (strMobile) {
    var regu = /^[1][1-9][0-9]{9}$/;
    var re = new RegExp(regu);
    if (re.test(strMobile)) {
      return true;
    } else {
      return false;
    }
  },
  getQueryString: function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  },
  setQueryString: function (data, url) {
    if (!data || $.type(data) != "object") {
      return;
    }

    var pathname = url || window.location.pathname;
    var str = "?",
      index = 0;

    if ($.isEmptyObject(data)) {
      str = "";
    } else {
      $.each(data, function (key, val) {
        str += (index > 0 ? "&" : "") + key + "=" + val;
        index++;
      });
    }

    return pathname + str;
  },
  // 获取随即字符串
  getRandomString: function () {
    var str = "",
      range = 30,
      arr = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

    // 随机产生
    for (var i = 0; i < range; i++) {
      str += arr[Math.round(Math.random() * (arr.length - 1))];
    }
    return str;
  },
  // Base64编码,支持中文
  Base64: {
    // 转码表
    table: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "+",
      "/",
    ],
    UTF16ToUTF8: function (str) {
      var res = [],
        len = str.length;
      for (var i = 0; i < len; i++) {
        var code = str.charCodeAt(i);
        if (code > 0x0000 && code <= 0x007f) {
          // 单字节，这里并不考虑0x0000，因为它是空字节
          // U+00000000 – U+0000007F 	0xxxxxxx
          res.push(str.charAt(i));
        } else if (code >= 0x0080 && code <= 0x07ff) {
          // 双字节
          // U+00000080 – U+000007FF 	110xxxxx 10xxxxxx
          // 110xxxxx
          var byte1 = 0xc0 | ((code >> 6) & 0x1f);
          // 10xxxxxx
          var byte2 = 0x80 | (code & 0x3f);
          res.push(String.fromCharCode(byte1), String.fromCharCode(byte2));
        } else if (code >= 0x0800 && code <= 0xffff) {
          // 三字节
          // U+00000800 – U+0000FFFF 	1110xxxx 10xxxxxx 10xxxxxx
          // 1110xxxx
          var byte1 = 0xe0 | ((code >> 12) & 0x0f);
          // 10xxxxxx
          var byte2 = 0x80 | ((code >> 6) & 0x3f);
          // 10xxxxxx
          var byte3 = 0x80 | (code & 0x3f);
          res.push(
            String.fromCharCode(byte1),
            String.fromCharCode(byte2),
            String.fromCharCode(byte3)
          );
        } else if (code >= 0x00010000 && code <= 0x001fffff) {
          // 四字节
          // U+00010000 – U+001FFFFF 	11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
        } else if (code >= 0x00200000 && code <= 0x03ffffff) {
          // 五字节
          // U+00200000 – U+03FFFFFF 	111110xx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        } /** if (code >= 0x04000000 && code <= 0x7FFFFFFF)*/ else {
          // 六字节
          // U+04000000 – U+7FFFFFFF 	1111110x 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        }
      }

      return res.join("");
    },
    encode: function (str) {
      if (!str) {
        return "";
      }
      var utf8 = this.UTF16ToUTF8(str); // 转成UTF8
      var i = 0; // 遍历索引
      var len = utf8.length;
      var res = [];
      while (i < len) {
        var c1 = utf8.charCodeAt(i++) & 0xff;
        res.push(this.table[c1 >> 2]);
        // 需要补2个=
        if (i == len) {
          res.push(this.table[(c1 & 0x3) << 4]);
          res.push("==");
          break;
        }
        var c2 = utf8.charCodeAt(i++);
        // 需要补1个=
        if (i == len) {
          res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0f)]);
          res.push(this.table[(c2 & 0x0f) << 2]);
          res.push("=");
          break;
        }
        var c3 = utf8.charCodeAt(i++);
        res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0f)]);
        res.push(this.table[((c2 & 0x0f) << 2) | ((c3 & 0xc0) >> 6)]);
        res.push(this.table[c3 & 0x3f]);
      }

      return res.join("");
    },
  },
  flatData,
  /**
   * Dstring:description
   * Kstirng:keywords
   */
  changeKandD: function (Dstring, Kstirng) {
    var metaList = document.getElementsByTagName("meta");
    let K = null;
    let D = null;
    for (var i = 0; i < metaList.length; i++) {
      if (metaList[i].getAttribute("name") === "keywords") {
        K = metaList[i];
      }
      if (metaList[i].getAttribute("name") === "description") {
        D = metaList[i];
      }
    }
    D.content = Dstring;
    K.content = Kstirng;
  },

  proxyInfo(callback) {
    const logHandler = {
      get: function (target, key) {
        console.log("proxyinfo获取");
        if (key === "inited") {
          if (target[key]) {
            callback();
          } else {
            let timer = setInterval(() => {
              if (target[key]) {
                timer = clearInterval(timer);
                callback();
              }
            }, 50);
          }
        }
      },
    };
    var act = new Proxy(window.loginSdk, logHandler);
    const inited = act.inited;
  },
};

<template>
  <div class="up-pic" @click.stop="">
    <div class="title">
      <h3 class="tit">按图片搜索</h3>
      <p class="explain">选择以下任何一种方式开始您的搜索</p>
    </div>

    <el-upload
      ref="clear"
      class="upload-demo"
      drag
      action="/api/image/search/upload"
      :on-exceed="handleExceed"
      :show-file-list="showfilelist"
      :on-success="uploadSuccessHandler"
      :on-error="uploadErrorHandler"
      :on-change="uploadChange"
      :before-upload="beforeAvatarUpload"
      :limit="1"
      :multiple="multiple"
    >
      <img
        src="@/assets/image/search/photo.png"
        class="drag_icon"
        alt=""
        srcset=""
      />
      <div class="el-upload__text">将文件拖到此处</div>
      <span class="huo">或</span>
      <div class="btn">上传图片…</div>
    </el-upload>
    <!-- 搜索框 -->
    <div class="photo-search">
      <img class="search-prefix" src="@/assets/image/search/link.png" />
      <div class="search-prepend">
        <img
          src="@/assets/image/common/search-999.png"
          alt=""
          srcset=""
          @click.stop="searchUrlHandler"
        />
      </div>
      <el-input
        placeholder="请在此处粘贴图片网址"
        v-model="searchUrl"
        @keydown.enter.native="searchUrlHandler"
      >
      </el-input>
    </div>
    <div class="tip">JPG或PNG图片，文件大小必须小于5MB</div>
    <div class="loading" v-if="loadTc">
      <i class="el-icon-loading iconload">
        <img src="@/assets/image/common/loadiing.png" alt="" srcset="" />
      </i>
      <p>正在上传…</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadTc: false,
      showfilelist: false,
      multiple: false,
      searchUrl: "",
    };
  },
  created() {},
  mounted() {
    window.addEventListener("click", this.windowClickHandler);
  },
  destroyed() {
    window.removeEventListener("click", this.windowClickHandler);
  },
  methods: {
    windowClickHandler() {
      this.$emit("close");
    },
    searchUrlHandler() {
      var _this = this;
      this.loadTc = false;
      let image_url = this.searchUrl;
      var cPattern =
        /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
      if (image_url.length == 0) {
        this.$message.warning(`请输入网址！`);
        return false;
      }
      var img = new Image();
      img.src = image_url;

      img.onload = function (data) {
        _this.gotoSearch(image_url);
      };
      img.onerror = function (data) {
        _this.$message.warning(`请输入正确的图片网址！`);
        return false;
      };
    },
    uploadChange() {
      this.loadTc = true;
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传 1 个文件`);
      setTimeout(() => {
        this.loadTc = false;
      }, 300);
    },
    uploadSuccessHandler(response, file, fileList) {
      var _this = this;
      if (response.status === "1" || response == 1) {
        let image_url = response.data.oss_url;
        _this.gotoSearch(image_url);
      } else {
        setTimeout(() => {
          this.loadTc = false;
        }, 300);
        this.$refs["clear"].clearFiles();
        this.$message.error(`${response.error}`);
      }
    },
    uploadErrorHandler(err, file, fileList) {
      this.$message.warning(`上传失败，请重新上传`);
      this.$refs["clear"].clearFiles();
      setTimeout(() => {
        this.loadTc = false;
      }, 300);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type == "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isLt1M = file.size / 1024 / 1024 > 1;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG或PNG 格式!");
        this.loadTc = false;
        this.$refs["clear"].clearFiles();
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        this.loadTc = false;
        this.$refs["clear"].clearFiles();
        return false;
      }
      if (isLt1M) {
        return this.commonZipPic(file);
      }
    },
    // 图片通用压缩提示
    commonZipPic(file) {
      const _this = this;
      return new Promise((resolve, reject) => {
        const image = new Image();
        let resultBlob = "";
        image.src = URL.createObjectURL(file);
        image.onload = () => {
          // 调用方法获取blob格式，方法写在下边
          resultBlob = _this.compressUpload(image, file);
          const fs = new File([resultBlob], file.name, {
            type: file.type,
          });
          resolve(fs);
        };
        image.onerror = () => {
          reject();
        };
      });
    },
    /* 图片压缩方法-canvas压缩 */
    compressUpload(image, file) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      // const initSize = image.src.length
      const { width } = image;
      const { height } = image;

      let width1, height1;
      if (width >= 800 || height >= 800) {
        if (width >= height) {
          width1 = 800;
          height1 = (800 / width) * height;
        } else {
          height1 = 800;
          width1 = (800 / height) * width;
        }
      } else {
        width1 = this.width;
        height1 = this.height;
      }
      canvas.width = width1;
      canvas.height = height1;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, width1, height1);
      // 进行最小压缩0.1
      const compressData = canvas.toDataURL(
        file.type || "image/jpeg",
        this.imgResizeRatio
      );
      // 压缩后调用方法进行base64转Blob，方法写在下边
      const blobImg = this.dataURItoBlob(compressData);
      return blobImg;
    },
    /* base64转Blob对象 */
    dataURItoBlob(data) {
      let byteString;
      if (data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(data.split(",")[1]);
      } else {
        byteString = unescape(data.split(",")[1]);
      }
      const mimeString = data.split(",")[0].split(":")[1].split(";")[0];
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    gotoSearch(url) {
      if (this.$route.name == "SearchPage") {
        // 修改地址栏参数
        if (url != this.$route.query.url) {
          this.$router.replace({
            name: "SearchPage",
            query: {
              url: url,
              type: 2, //以图搜图
            },
          });
        }
        // 请求数据
        this.$emit("search", 2, url);
      } else {
        this.$emit("close");
        this.$router.push({
          name: "SearchPage",
          query: {
            url: url,
            type: 2, //以图搜图
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .uppic-box {
// position: absolute;
// top: 0;
// left: 0;
// z-index: 2000;
// background: rgba(0, 0, 0, 0.3);
// width: 100%;
// height: 100%;

.up-pic {
  width: 500px;
  height: 410px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 100%;
  margin-top: 2px;
  padding: 0 19px;
  z-index: 10;
  .title {
    @include flex(center, space-between);
    height: 50px;
    h3 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0a051b;
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #767676;
    }
  }

  .photo-search {
    width: 462px;
    height: 36px;
    border-radius: 4px;
    margin: 14px auto 17px;
    border: 1px solid #d1d1d1;
    ::v-deep .el-input {
      box-sizing: border-box;
      font-size: 16px;
      height: 34px;
      border: 0;
      line-height: 34px;
      width: 100%;

      .el-input__inner {
        -webkit-appearance: none;
        background-color: transparent;
        background-image: none;
        border: 0px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #333333;
        font-size: inherit;
        height: 34px;
        float: left;
        outline: 0;
        padding: 0 28px 0 36px;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;

        &::-webkit-input-placeholder {
          color: #767676;
        }

        &::-moz-placeholder {
          color: #767676;
        }

        &::-ms-input-placeholder {
          color: #767676;
        }
      }
    }
    position: relative;
    .search-prefix {
      width: 12px;
      height: 12px;
      position: absolute;
      left: 19px;
      top: 12px;
    }
    .search-prepend {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      box-sizing: border-box;
      padding: 7px 14px;
      z-index: 1;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  ::v-deep .el-upload {
    width: 100%;
    height: 250px;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      border: 1px dashed #d1d1d1;

      .drag_icon {
        width: 99px;
        margin: 35px auto 0;
      }

      .el-upload__text {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0a051b;
        line-height: 25px;
        text-align: center;
        margin-top: 22px;
      }

      .huo {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        text-align: center;
        margin-top: 9px;
      }

      .btn {
        margin: 14px auto 0;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #147eff;
        line-height: 17px;
        text-align: center;
      }
    }
  }

  .tip {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #767676;
    line-height: 17px;
    text-align: center;
  }

  .loading {
    width: 100px;
    height: 100px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;

    .el-icon-loading {
      font-size: 36px;
      color: #fff;
      margin-top: 18px;
      width: 36px;

      img {
        width: 100%;
      }

      &:before {
        content: "";
      }
    }

    p {
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}
// }
</style>
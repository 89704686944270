<template>
  <div class="hc-search home-border">
    <div class="type" @mousemove="mousemOve" @mouseleave="mouseLeave">
      <div>
        {{ seachTypeData }}<span class="iconfont icon-xiangxiajiantou"></span>
      </div>
      <ul v-if="ulShow" @mouseleave="mouseLeave" class="select">
        <li @click="changeSeachType('图片')">图片</li>
        <li @click="changeSeachType('视频')">视频</li>
      </ul>
    </div>
    <div class="search-layout">
      <div class="search img l imgT">
        <img class="search l" @click.stop="goSearch" src="@/assets/image/search/search.png" alt="" />
        <img class="search l hover" @click.stop="goSearch" src="@/assets/image/search/search-000.png" alt="" />
      </div>
      <input type="text" @keyup.enter="goSearch" v-model="keyword" placeholder="开启您的创意之旅…" />
    </div>

    <upload-image-dialog v-if="uploadPic" @close="uploadPic = false"></upload-image-dialog>
  </div>
</template>

<script>
import UploadImageDialog from "@/components/uploadImage";

export default {
  components: { UploadImageDialog },
  data () {
    return {
      uploadPic: false,
      keyword: "",
      seachTypeData: "",
      ulShow: false,
    };
  },
  created () {
    this.active(this.$route.name);
  },
  methods: {
    active (name) {
      this.keyword = this.$route.query.keyword ? this.$route.query.keyword : "";
      if (name == "SearchPage") {
        this.seachTypeData = "图片";
      } else if (name == "MusicSearchPage") {
        this.seachTypeData = "音乐";
      } else if (name == "DesignSearchPage") {
        this.seachTypeData = "设计";
      } else if (name == "VideoSearchPage") {
        this.seachTypeData = "视频";
      } else if (name == "ExemptPage") {
        this.seachTypeData = "免抠元素";
      } else if (name == "PptPage") {
        this.seachTypeData = "PPT模板";
      }
    },
    mouseLeave () {
      this.ulShow = false;
    },
    mousemOve () {
      this.ulShow = true;
    },
    changeSeachType (data) {
      this.ulShow = false;
      this.seachTypeData = data;
    },
    goSearch () {
      console.log(1111);
      if (this.seachTypeData == "图片") {
        window.location.href = "/search?keyword=" + this.keyword + "&type=" + 1;
        /* this.$router.push({
          name: "SearchPage",
          query: {
            keyword: this.keyword,
            type: 1,
          },
        }); */
      } else if (this.seachTypeData == "音乐") {
        window.location.href = "/music?keyword=" + this.keyword;
        /* this.$router.push({
          name: "MusicSearchPage",
          query: {
            keyword: this.keyword,
          },
        }); */
      } else if (this.seachTypeData == "设计") {
        window.location.href = "/design?keyword=" + this.keyword;
        /* this.$router.push({
          name: "DesignSearchPage",
          query: {
            keyword: this.keyword,
          },
        }); */
      } else if (this.seachTypeData == "视频") {
        window.location.href = "/video?keyword=" + this.keyword;
        /* this.$router.push({
          name: "VideoSearchPage",
          query: {
            keyword: this.keyword,
          },
        }); */
      } else if (this.seachTypeData == "免抠元素") {
        window.location.href = "/exempt?keyword=" + this.keyword;
        /* this.$router.push({
          name: "ExemptPage",
          query: {
            keyword: this.keyword,
          },
        }); */
      } else if (this.seachTypeData == "PPT模板") {
        window.location.href = "/ppt?keyword=" + this.keyword;
        /* this.$router.push({
          name: "PptPage",
          query: {
            keyword: this.keyword,
          },
        }); */
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hc-search {
  width: 100%;
  height: 40px;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 0 21px 0 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  transition: 0.3s;

  .type {
    float: left;
    width: 114px;
    padding: 10px 0 0;
    cursor: pointer;

    div {
      padding-left: 23px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0a051b;
      line-height: 20px;
      border-right: 1px solid #767676;

      // margin-right: 16px;
      span {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 14px;
      }
    }

    ul {
      width: 110px;
      margin-top: 10px;
      padding: 10px 0;
      box-sizing: border-box;
      text-align: center;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      transition: 0.3s;

      li {
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0a051b;
        line-height: 40px;
        cursor: pointer;
        position: relative;
        text-align: center;

        .new_icon {
          position: absolute;
          right: 20px;
          top: 10px;
          width: 16px;
        }

        &:hover,
        &.active {
          width: 94px;
          height: 40px;
          background: #edf2f5;
          border-radius: 8px;
          margin: 0 auto;

          .new_icon {
            right: 17px;
          }
        }
      }
    }
  }

  input {
    width: 100%;
    font-size: 14px;
    background: none;
    float: left;

    &::placeholder {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #767676;
    }
  }

  .img {
    margin-top: 20px;

    cursor: pointer;

    img {
      @include wh(20px, 20px);
    }

    &:hover {
      img {
        display: none;
      }

      img.hover {
        display: block;
      }
    }

    .hover {
      display: none;
    }

    &.search {
      margin-right: 8px;
      margin-left: 16px;
    }

    &.photo {
      position: relative;
    }
  }
}

.select {
  border: 1px solid #e9e9eb;
}

.search-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .imgT {
    margin-top: 0px !important;
  }
}
</style>

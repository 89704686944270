import { User, Login } from "@/config/axios/api";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    loginInfo: null,
    pageScroll: false,
    showLogin: false,
    isKeyword: false,
    currentItem: null,
    recom: [],
    activity: false,
    xuanf: false,
    headerShow: false,
  },
  mutations: {
    setLoginInfo(state, data) {
      state.loginInfo = data;
    },
    watchPageScroll(state, data) {
      state.pageScroll = data;
    },
    watchShowActivity(state, data) {
      state.activity = data;
    },
    watchShowXuanf(state, data) {
      state.xuanf = data;
    },

    watchShowLogin(state, data) {
      state.showLogin = data;
    },
    watchSearchType(state, data) {
      state.isKeyword = data;
    },
    setCurrentItem(state, data) {
      state.currentItem = data;
    },
    setRecom(state, data) {
      state.recom = data;
    },
    setHeaderShow(state, data) {
      state.headerShow = data;
    },
  },
  actions: {
    loginInfo({ commit }, loginObj = {}) {
      function logout() {
        Login.loginout().then((res) => {
          if (res.status == 1) {
            // 本地清除cookie
            if (process.env.NODE_ENV === "development") {
              Vue.prototype.staticFunc.setCookie("UserId", "");
            }
            commit("setLoginInfo", null);
            window.localStorage.clear();
            window.sessionStorage.clear();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        });
      }
      return new Promise((resolve) => {
        User.getInfo(loginObj).then((res) => {
          if (res && res.status === 1) {
            if (
              process.env.NODE_ENV === "production" &&
              res.data.repeat_login == 2
            ) {
              Vue.prototype.$message.error(
                "您的帐号已在别处登录，如非本人操作，请注意帐号安全，及时修改密码"
              );
              logout();
              resolve(0);
              return;
            }
            let data = res.data;
            commit("setLoginInfo", data);
            resolve(data);
          } else {
            resolve(0);
            logout();
          }
        });
      });
    },
  },
  modules: {},
  getters: {
    loginInfo: (state) => state.loginInfo,
    headerShow: (state) => state.headerShow,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
});

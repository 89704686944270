<template>
  <div id="app">
    <Header v-if="!$route.meta.hideHeader" :navtxt="$route.name" ref="child" :headerOpacity="$route.meta.headerOpacity"
      v-cloak />
    <transition name="fade">
      <router-view />
    </transition>
    <pay v-if="payInfo.visible" :payInfo="payInfo" @close="closePay" />
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import { ImgApi, GlobalApi } from "@/config/axios/api";
import { mapState, mapMutations } from "vuex";
import Pay from "@/components/pay";

export default {
  name: "App",
  components: {
    Header,
    Pay,
  },
  data () {
    return {
      payInfo: {
        visible: false
      },
    };
  },
  created () {
    this.$bus.$on('downloaddemo', this.downloaddemo)
    this.$bus.$on('delCollect', this.delCollect)
    this.$bus.$on('delVideoCollect', this.delVideoCollect)
  },
  computed: {
    ...mapState(["loginInfo"]),
  },
  destroyed () {
    this.$bus.$off('downloaddemo');
    this.$bus.$on('delCollect')
    this.$bus.$on('delVideoCollect')
  },
  mounted () {

  },
  methods: {
    ...mapMutations(["watchShowLogin", "setLoginInfo"]),
    // 下载样图 / 原图
    downloaddemo (item, type) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      ImgApi.downloadDemo({ id: item.editor_id, type: type }).then((res) => {
        if (res.status == 1) {
          if (res.data.download_url) {
            this.staticFunc.downloadImg(res.data.download_url, item.editor_id, () => { });
            this.$message.success({ message: res.message ? res.message : "图片下载成功" });
          }
        } else {
          if (this.loginInfo.is_sc_vip != 1 || (type == 'original' && this.loginInfo.sc_vip_level != 3)) {
            this.payInfo.visible = true;
            this.payInfo.payment_channel = type == 'demo' ? '下载样图' : '下载原图';
          }
        }
      });
    },
    // 取消收藏
    delCollect (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      GlobalApi.delCollection({ id: item.editor_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            type: "success",
            message: "图片已取消收藏!",
          });
          item.is_favorite = false;
          item.is_collect = false;
        }
      });
    },
    // 取消视频收藏
    delVideoCollect (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      GlobalApi.delVideosCollection({ id: item.editor_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            type: "success",
            message: "视频已取消收藏!",
          });
          item.is_favorite = false;
          item.is_collect = false;
        }
      });
    },
    // 关闭支付弹窗
    closePay (refresh) {
      if (refresh) {
        this.$store.dispatch("loginInfo")
      }
      this.payInfo.visible = false;
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/common.scss";
body {
  min-width: 1200px;
}

[v-cloak] {
  display: none !important;
}

// 预览图片样式
.img-preview {
  position: fixed;
  top: 0;
  left: 0;
  @include wh(100%, 100%);
  @include flex(center, center);
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;

  img {
    max-width: 98%;
    height: 619px;
    object-fit: contain;
    position: relative;
    float: left;
  }

  div {
    div {
      position: relative;
      z-index: 1;
      margin-top: 15px;
      margin-left: -50px;
      width: 35px;
      height: 35px;
      background: #f9f9f9;
      border-radius: 6px;
      cursor: pointer;
      // line-height: 30px;
      display: inline-block;

      span {
        color: #757580;
        font-size: 16px;
        float: left;
        margin: 9px 0 0 10px;
      }
    }
  }
}
</style>

import http from "./index";

const $ajax = (url, data, method = "GET", obj) => {
  let params = obj ? { url, method, data, ...obj } : { url, method, data };
  return http.httpServer(params);
};
export const Login = {
  wechatCode: (data) => $ajax("/api/weixin/login", data, "GET"), // 获取微信二维码
  wechatLogin: (data) => $ajax("/api/weixin/info", data, "GET"), // 轮询微信二维码
  passwordLogin: (data) => $ajax("/user/login", data, "POST"),
  weiboLogin: (data) => $ajax("/api/weibo/login", data),
  register: (data) => $ajax("/user/register", data, "POST"), //注册
  forgetPassword: (data) => $ajax("/person/find", data, "POST"), //忘记密码
  registerSms: (data) => $ajax("/user/sms", data, "POST"), //发送验证码
  wechatToLogin: (data) => $ajax("/api/user/login", data, "POST"), //微信登录
  loginout: (data) => $ajax("/user/loginout", data, "GET"), //退出登陆
};

export const User = {
  getInfo: (data) => $ajax("/sc/api/user/info", data, "POST"), // 获取用户信息
  getAmount: (data) => $ajax("/sc/api/user/download/amount", data, "GET"), // 获取用户版权素材下载额度
  editName: (data) => $ajax("/person/nickname", data, "POST"), // 获取用户信息
  uploadFile: (data) => $ajax("/upload/file", data, "POST"), // 上传头像
  editHead: (data) => $ajax("/person/edit/head", data, "POST"), // 修改头像
  checkold: (data) => $ajax("/person/checkold", data, "POST"), // 修改手机号，验证老手机号
  bindmobile: (data) => $ajax("/person/bindmobile", data, "POST"), // 绑定新手机号
  bindweibo: (data) => $ajax("/api/weibo/bind", data, "GET"), // 绑定微博
  editPass: (data) => $ajax("/person/password", data, "POST"), //修改密码
};
export const Home = {
  getBannerList: (data) => $ajax("/sc/home/banner/list", data), //获取banner
  getHotDetail: (data) => $ajax("/sc/home/recommend/hot/material", data), //获取热门图集详情
  topRecommendations: (data) => $ajax("/sc/home/recommend/list", data, "GET"), //热门推荐 + 素材数据
};
export const SearchApi = {
  getImage: (data) => $ajax("/sc/images", data, "GET"), //图片搜索
  getVideo: (data) => $ajax("/sc/videos", data, "GET"), //视频搜索
};
//视频
export const VideoApi = {
  //视频详情
  videoDetail: (data) => $ajax("/sc/videos_detail", data, "GET"),
  //个人视频下载
  videoCopyrightDownload: (data) =>
    $ajax("/sc/api/videos/download", data, "GET"),
};
export const VipApi = {
  getVipList: (data) => $ajax("/sc/api/package/list", data, "POST"), //获取会员套餐列表
  generateOrder: (data) => $ajax("/sc/api/generate/order", data, "POST"), //生成订单
  wechatPay: (data) => $ajax("/sc/api/wechat/order", data, "POST"), //获取微信支付二维码
  aliPay: (data) => $ajax("/sc/api/ali/order", data, "POST"), //获取支付宝支付二维码
  orderCheck: (data) => $ajax("/sc/api/check/order", data, "POST"), //查询订单状态
};
export const ImgApi = {
  getInfo: (data) => $ajax("/sc/images_detail", data, "GET"), //获取图片详情
  downloadDemo: (data) => $ajax("/sc/api/images/download", data, "GET"), //下载样图
};

export const GlobalApi = {
  getAuthorizeStatus: (data) =>
    $ajax("/sc/api/user/authorize/subject/status", data, "get"), //查看授权状态
  addAuthorize: (data) =>
    $ajax("sc/api/user/authorize/subject/add", data, "POST"), //被授权主体-添加
  getRecordList: (data) => $ajax("/sc/api/user/downloads", data, "GET"), //版权素材下载记录

  getDirectoryList: (data) => $ajax("/sc/api/user/favorites/list", data, "get"), //获取收藏夹列表
  addDirectory: (data) => $ajax("/sc/api/user/favorites/add", data, "POST"), //新增收藏夹
  editDirectory: (data) => $ajax("/sc/api/user/favorites/edit", data, "POST"), //编辑收藏夹
  delDirectory: (data) => $ajax("/sc/api/user/favorites/del", data, "POST"), //删除收藏夹

  getCollectionList: (data) =>
    $ajax("/sc/api/user/favorites/material/list", data, "GET"), //收藏列表
  addCollection: (data) => $ajax("/sc/api/images/favorites", data, "POST"), //图片 添加收藏
  addVideosCollection: (data) =>
    $ajax("/sc/api/videos/favorites", data, "POST"), //视频 添加收藏
  delCollection: (data) =>
    $ajax("/sc/api/images/favorites/remove", data, "POST"), //取消图片收藏
  delVideosCollection: (data) =>
    $ajax("/sc/api/videos/favorites/remove", data, "POST"), //取消视频收藏
  moveCollection: (data) =>
    $ajax("/sc/api/user/favorites/material/move", data, "POST"), //移动团队收藏

  getOrderList: (data) => $ajax("/sc/api/order/list", data, "POST"), //团队-订单中心-列表
  revokeOrder: (data) => $ajax("/sc/api/close/order", data, "POST"), //团队-订单中心-取消订单

  downloadRecordPrincipal: (data) =>
    $ajax("/sc/api/user/downloads/authorize", data, "POST"), //下载授权书

  getInvoice: (data) => $ajax("/sc/api/user/invoice/info", data, "GET"), //获取发票信息
  editInvoice: (data) => $ajax("/sc/api/user/invoice/add", data, "POST"), //编辑发票信息
};

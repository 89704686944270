<template>
  <div class="login-container" v-if="!$parent.showProtocol">
    <template>
      <div class="header-adv">
        <a :href="'javascript:;'"><img src="@/assets/image/logindialog/adv.png"></a>
      </div>
      <div class="inner">
        <div class="login-nav">
          <div class="nav-item" :class="{ active: dialogType == 'qrcode' }" @click="changeType('qrcode')">微信扫一扫
          </div>
          <div class="nav-item" :class="{ active: dialogType == 'form' }" @click="changeType('form')">手机号登录</div>
          <div class="nav-bottom-line" :style="{ left: dialogType == 'qrcode' ? '76px' : '231px' }"></div>
        </div>
        <div class="login-content">
          <div class="login-qrcode" v-show="dialogType == 'qrcode'">
            <div class="qrcode-image">
              <img :src="qrcodeInfo.url" alt="二维码" v-if="qrcodeInfo && qrcodeInfo.url" />
              <div class='timeout-tips' v-if="login_timeout >= 60000" @click="getWeixinQrcode">请点击刷新</div>
            </div>
            <p>微信扫描二维码登录</p> <span class="qrcode-timeout">二维码已过期，请点击刷新</span>
          </div>
          <div class="login-form" v-show="dialogType == 'form'">
            <div class="form-item"> <input type="text" v-model="mobile" @keydown.enter="toLogin" name="mobile"
                placeholder="请输入您的手机号" autocomplete="off"> </div>
            <div class="form-item"> <input type="password" v-model="password" @keydown.enter="toLogin" name="password"
                placeholder="请输入您的密码" autocomplete="off">
            </div>
            <div class="form-item"> <input type="text" name="vcode" v-model="code" @keydown.enter="toLogin"
                placeholder="请输入右侧验证码" maxlength="4" style="width: 165px;"> <span class="vcode-img">
                <img v-show="dialogType == 'form'" ref="imgEl" src="" alt="验证码"
                  @click.stop="$refs.imgEl.src = imgcodeurl + getRandom()">
              </span>
            </div>
            <div class="form-pwd">
              <div class="remember"> <em :class="{ active: rememberPassword }"
                  @click="rememberPassword = !rememberPassword"><i class="iconfont icon-duigou"></i></em>
                <span>记住密码</span>
              </div> <a href="/forget" target="_blanck">忘记密码</a>
            </div>
            <div class="form-submit"> <button type="button" @click="toLogin">立即登录</button> </div>
            <div class="form-error">{{ errmsg }}</div>
            <div class="form-register"> 还没有账号?<a href="/register" target="_blanck">快去注册</a> </div>
          </div>
        </div>
        <div class="login-by">
          <div class="by-item" data-by="qq" @click="getQQLogin"> <img src="@/assets/image/logindialog/editor_qq.png" />
            <span>QQ登录</span>
          </div>
          <div class="by-item" data-by="weibo" @click="getWeiboLogin"> <img
              src="@/assets/image/logindialog/editor_weibo.png" />
            <span>微博登录</span>
          </div>
        </div>
        <div class="agreedService" style="height: 21px;"><em :class="{ active: agreedService }"
            @click="agreedService = !agreedService"><i class="iconfont icon-duigou"></i></em>
          我同意<a href="javascript:void(0)" @click="$bus.$emit('changeShowProtocol', true)">《365编辑器用户注册协议》</a></div>
        <div class="login-close" @click="$emit('close')"><i class="iconfont icon-guanbi1"></i></div>
      </div>
    </template>
  </div>
</template>

<script>
import { Login } from "@/config/axios/api";
import { mapState, mapMutations } from "vuex";

export default {
  name: "loginDialog",
  data () {
    return {
      rememberPassword: true,   //记住密码
      agreedService: true,    //同意协议
      dialogType: "qrcode",  //登陆类型
      advInfo: null,   //广告信息
      qrcodeInfo: null,   //二维码信息
      login_timeout: 0,   //二维码过期时间
      mobile: '',
      password: '',
      code: '',
      errmsg: '',
      login_origin: '',
      login_keyword: '',
      login_url: '',
      imgcodeurl: (process.env.NODE_ENV === "development" ? '/api' : process.env.VUE_APP_BaseUrl) + this.getImgCodeUrl + '?_='
    };
  },
  computed: {
    ...mapState(["loginInfo"]),

  },
  components: {
  },
  created () {
    this.getWeixinQrcode()
    this.initParams()
  },
  watch: {
  },
  mounted () {

  },
  methods: {
    ...mapMutations(["watchShowLogin", "setLoginInfo"]),
    getRandom () {
      return 10000 + Math.round(Math.random() * (99999 - 10000))
    },
    initParams () {
      var url = window.location.href
      window.localStorage.setItem('url', url)

      this.login_origin = localStorage.getItem('login_origin') ? localStorage.getItem('login_origin') : "";
      this.login_keyword = localStorage.getItem('login_keyword') ? localStorage.getItem('login_keyword') : "";
      this.login_url = localStorage.getItem('login_url') ? localStorage.getItem('login_url') : "";

      if (this.login_url == "") {
        localStorage.setItem('login_url', window.location.href);
        if (this.$route.query.f) {
          this.login_origin = decodeURIComponent(this.$route.query.f);
          localStorage.setItem('login_origin', this.login_origin);
        }
        if (this.$route.query.k) {
          this.login_keyword = decodeURIComponent(this.$route.query.k);
          localStorage.setItem('login_keyword', this.login_keyword);
        }
      }
    },
    changeType (type) {
      this.dialogType = type;
      if (type == 'qrcode') {
        this.getWeixinQrcode();
      } else if (this.$refs.imgEl.src.indexOf('/sc/api/captcha/code') < 0) {
        this.$refs.imgEl.src = this.imgcodeurl + this.getRandom();
      }

    },
    // 获取微信二维码
    getWeixinQrcode () {
      Login.wechatCode({ url: window.location.href, is_sc: 1 }).then(res => {
        this.qrcodeInfo = res;
        this.login_timeout = 0;
        this.getWeixinLoginResult()
      })
    },
    getWeixinLoginResult () {
      Login.wechatLogin({ ticket: this.qrcodeInfo.ticket, nonce: this.qrcodeInfo.nonce }).then(res => {
        if (res != 1) {
          // 成功
          let params = {};
          params.info = JSON.stringify(res);
          params.ticket = this.qrcodeInfo.ticket;
          params.nonce = this.qrcodeInfo.nonce;
          Login.wechatToLogin(params).then(respon => {
            if (respon.status == 1) {
              this.afterLoginOperate(res);
            }
          })
        } else {
          this.login_timeout += 1000;
          if (this.login_timeout < 60000 && this.dialogType == 'qrcode' && this.$store.state.showLogin) {
            setTimeout(() => {
              this.getWeixinLoginResult();
            }, 1000)
          }
        }
      })
    },
    toLogin () {
      var conditions = {};
      conditions.mobile = this.mobile;
      conditions.password = this.password;
      conditions.vcode = this.code;
      conditions.url = decodeURIComponent(this.login_url)
      if (!conditions.mobile || !conditions.password || !conditions.vcode) {
        this.errmsg = '手机号、密码、验证码均不可为空'
        return false;
      }
      this.errmsg = '';
      Login.passwordLogin(conditions).then(res => {
        if (res.status == 1) {
          if (this.rememberPassword) {
            localStorage.username = conditions.mobile;
            localStorage.password = conditions.password;
          } else {
            localStorage.username = '';
            localStorage.password = '';
          }
          this.afterLoginOperate(res);
        } else {
          this.$refs.imgEl.src = this.imgcodeurl + this.getRandom()
        }
      })
    },

    // QQ登录
    getQQLogin () {
      if (localStorage.getItem("isBaiduForm")) {
        localStorage.setItem("isBaiduForm", "1");
      }
      window.location.href = this.editorUrl + "/api/qq/login?is_sc=1&state=" + this.login_origin + '&keyword=' + this.login_keyword + '&url=' + decodeURIComponent(this.login_url);
    },
    getWeiboLogin () {
      var newTab = window.open('about:blank');
      Login.weiboLogin({
        state: this.login_origin,
        keyword: this.login_keyword,
        is_sc: 1,
        url: decodeURIComponent(this.login_url)
      }).then(backData => {
        if (localStorage.getItem("isBaiduForm")) {
          localStorage.setItem("isBaiduForm", "1");
        }
        newTab.location.href = backData;
      })
    },
    // 登录后的操作
    afterLoginOperate (res) {
      if (process.env.NODE_ENV === "development") {
        this.staticFunc.setCookie('UserId', res.UserId)
      }
      this.$store.dispatch("loginInfo").then(res => {
        if (res) {
          location.reload();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$font_tip: #909399;
$font_active: #3f9eff; // 高亮颜色 

.login-container {
  color: #303133;
  -webkit-font-smoothing: antialiased;
  font-family: "PingFang SC", "Lantinghei SC", "Open Sans", Arial, "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  position: fixed;
  box-sizing: content-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 999999;
  @include flex(center, center);

  .header-adv {
    width: 320px;
    height: 480px;
    border-radius: 12px 0 0 12px;
    overflow: hidden;
    background: #fff;

    a {
      display: block;
      width: 100%;
      height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .inner {
    width: 370px;
    height: 480px;
    background: #fff;
    border-radius: 0px 12px 12px 0px;
    padding-bottom: 20px;
    box-sizing: border-box;
    position: relative;


    .login-nav {
      padding: 8px 29px 0;
      height: 50px;
      @include flex(center, space-between);
      position: relative;

      .nav-item {
        @include flex(center, center);
        position: relative;
        height: 52px;
        width: 50%;
        font-size: 16px;
        color: #666666;
        cursor: pointer;
        transition: all .3s ease;
        font-weight: 500;

        &.active {
          color: #333333;
        }
      }

      .nav-bottom-line {
        position: absolute;
        width: 60px;
        height: 3px;
        bottom: 3px;
        left: 76px;
        transition: all .2s;
        background: rgba(121, 188, 255, 1);
        border-radius: 2px;
      }
    }

    .login-content {
      padding: 0 43px;
      box-sizing: border-box;

      .login-qrcode {
        width: 100%;
        height: 100%;
        padding-top: 54px;
        padding-bottom: 27px;
        text-align: center;
        position: relative;
        box-sizing: border-box;

        .qrcode-image {
          width: 196px;
          height: 196px;
          border: 1px solid #EBEBEB;
          margin: 0 auto;
          position: relative;

          .timeout-tips {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            @include flex(center, center);
            background-color: rgba(255, 255, 255, 0.9);
            color: #36aeea;
            cursor: pointer;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        p {
          color: $font_tip;
          margin-top: 18px;
        }

        .qrcode-timeout {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, .9);
          @include flex(center, center);
          color: $font_active;
          cursor: pointer;
          display: none;
        }
      }

      .login-form {
        padding-top: 33px;

        .form-item {
          width: 100%;
          position: relative;
          margin-bottom: 20px;

          .iconfont {
            position: absolute;
            left: 15px;
            top: 9px;
            color: $font_tip;
            font-size: 22px;
          }

          input {
            width: 100%;
            height: 36px;
            line-height: 36px;
            padding-left: 12px;
            box-sizing: border-box;
            color: #606266;
            transition: all .2s ease;
            outline: none;
            border: 1px solid #ebebeb;
            border-radius: 4px;

            &:focus {
              border: 1px solid #3f9eff;
            }

            &::placeholder {
              color: $font_tip;
              opacity: 1;
            }
          }

          .vcode-img {
            position: absolute;
            right: 0px;
            bottom: 1px;
            width: 107px;
            height: 34px;
            margin-left: 8px;
            border-radius: 4px;
            cursor: pointer;
            @include flex(center, center);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .form-pwd {
          @include flex(center, space-between);
          padding: 0px 0 18px 0;
          box-sizing: border-box;
          margin-top: -8px;

          .remember {
            @include flex(center, center);

            em {
              width: 14px;
              height: 14px;
              border: 1px solid #dfdfdf;
              @include flex(center, center);
              margin-right: 8px;
              transition: all .2s;
              cursor: pointer;

              .iconfont {
                transition: all 2s;
                color: #dfdfdf;
                font-size: 10px;
              }

              &.active {
                width: 14px;
                height: 14px;
                background: rgba(106, 169, 248, 1);
                border-radius: 2px;

                .iconfont {

                  color: #fff;

                }
              }
            }
          }

          &>a {
            color: $font_active;
          }
        }

        .form-submit {
          width: 100%;

          button {
            border: none;
            outline: none;
            width: 100%;
            height: 38px;
            color: #fff;
            border-radius: 4px;
            background: #87C3FD;
            font-size: 15px;
            @include flex(center, center);
          }
        }

        .form-error {
          font-size: 13px;
          color: #F56C6C;
          text-align: center;
          margin: 4px 0 4px 0;
          height: 19px;
        }

        .form-register {
          text-align: center;

          &>a {
            color: $font_active;
            margin-left: 6px;
          }
        }
      }
    }

    .login-by {
      @include flex(center, center);
      box-sizing: border-box;
      margin-top: 24px;

      .by-item {
        width: 150px;
        color: $font_tip;
        cursor: pointer;
        @include flex(center, center);

        &:hover {
          color: $font_active;

          .iconfont {
            color: $font_active;
            border-color: $font_active;
          }
        }

        img {
          width: 32px;
          height: 32px;
          background: rgba(238, 238, 238, 1);
          border-radius: 50%;
          padding: 4px;
          box-sizing: border-box;
          margin-right: 6px;
        }
      }
    }

    .agreedService {
      @include flex(center, center);
      margin-top: 16px;

      a {
        color: #3f9eff;
      }

      em {
        width: 14px;
        height: 14px;
        border: 1px solid #dfdfdf;
        @include flex(center, center);
        margin-right: 8px;
        transition: all .2s;
        cursor: pointer;

        .iconfont {
          transition: all 2s;
          color: #dfdfdf;
          font-size: 10px;
        }

        &.active {
          width: 14px;
          height: 14px;
          background: rgba(106, 169, 248, 1);
          border-radius: 2px;

          .iconfont {
            color: #fff;

          }
        }
      }
    }

    .login-close {
      position: absolute;
      right: -42px;
      top: -16px;
      width: 30px;
      height: 30px;
      @include flex(center, center);
      color: #fff;
      cursor: pointer;

      i {
        font-size: 28px;
      }
    }
  }




}
</style>

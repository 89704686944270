<template>
  <!-- 支付弹窗 -->
  <div class="pay banquan-dialog">
    <div class="pay-inner">
      <div class="payheader">
        <div class="icon">
          <img :src="loginInfo.headimg" alt="">
          <p>{{loginInfo.NickName}}</p>
          <div class="vip">
            <span v-if="loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 1">月会员</span>
            <span v-else-if="loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 2">普通年会员</span>
            <span v-else-if="loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 3">年PLUS会员</span>
          </div>
        </div>
        <div class="title">
          <p>个人VIP</p>
          <span>个人商用授权（非工商注册主体）</span>
        </div>
      </div>
      <div class="top-box">
        <div class="payContent-list">
          <ul class="payContent-card">
            <li class="payContent-card-price " :class="{active: item.id == checkData.id, not: canBuy(item)}"
              v-for="item in vipData" :style="item.id == checkData.id ? '' : 'padding: 1px'" :key="item.id"
              @click="canBuy(item) ? '' : buyFun(item)">
              <div class="jb-box" v-if="item.level == 2">低至1.7元/月</div>
              <div class="jb-box" v-if="item.level == 3" style="width: 105px">低至2.5元/月</div>
              <h5 v-if="item.level == 1"><img src="@/assets/image/vip/month.png" alt="" />月会员</h5>
              <h5 v-if="item.level == 2"><img src="@/assets/image/vip/nian.png" alt="" />普通年会员</h5>
              <h5 v-if="item.level == 3"><img src="@/assets/image/vip/nianplus.png" alt="" />年PLUS会员</h5>
              <section class="price">
                <em>￥</em><span>{{item.price}}</span>
                <p>{{item.level == 1 ? '/月' : '/年'}}</p>
              </section>
              <div class="origin-price">原价￥{{item.level == 1 ? '19/月' : item.level == 2 ? '99/年' : '198/年'}}</div>
              <div class="desc">版权素材下载{{item.level == 1 ? 10 : item.level == 2 ? 30 : 100}}条/天</div>
              <div class="desc">样图下载10张/天</div>
              <div class="jiaob">
                <i class="iconfont icon-duigou"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="bott-box">
        <div class="pay-code" style="margin-right: 16px">
          <div>
            <img :src="payCode" v-if="payCode" alt="">
          </div>
          <div class="code-msg" @click="getPayCode()" v-if="showCodeMsg">
            二维码已过期<br />点击刷新
          </div>
          <p>微信支付</p>
        </div>
        <div class="pay-code">
          <iframe :srcdoc="aliHtmlData"></iframe>
          <div class="code-msg" @click="getPayCode()" v-if="showCodeMsg">
            二维码已过期<br />点击刷新
          </div>
          <p>支付宝支付</p>
        </div>
        <div class="pay-info">
          <p class="price">支付金额：<span>¥{{checkData.price}}</span></p>
          <div class="type">
            <img src="@/assets/image/common/zhifubao.png" alt="">
            <img src="@/assets/image/common/weixin.png" alt="">
            请使用支付宝/微信扫码付款
          </div>
          <p class="xianyi">
            支付即代表您同意<a href="javascript:;" @click="protocolVisible = true">《365编辑器VIP服务协议》</a>
          </p>
        </div>
      </div>
      <div class="paytip">
        <p>个人专业VIP最低<span>低至1.7元/月</span></p>
        <div>权益内容：<span>正版授权 商用无忧</span><span>海量素材 独家版权</span><span>正规发票 在线申请</span></div>
      </div>
      <div class="tip">授权范围：中国（含港澳台地区） ｜ 社交平台发布互联网发布 ｜ 移动互联网发布 ｜ 现场演出</div>
      <div class="banquan-close" @click="closePay">
        <span class="iconfont icon-close"></span>
      </div>
    </div>
    <!-- 协议弹框 -->
    <el-dialog v-cloak title="365编辑器会员服务协议" class="dialog-vip-protocol" :visible.sync="protocolVisible" width="930px"
      :append-to-body="true">
      <el-scrollbar>
        <p>
          欢迎使用北京微梦传媒股份有限公司旗下产品365编辑器的会员服务，为了保障您的权益，请在进行下一步操作前，详细阅读本协议的所有内容，特别是加粗部分。请您仔细阅读、理解并遵守《365编辑器会员服务协议》（以下简称“本协议”）内容。本协议由您与365编辑器会员服务提供方共同缔结，具有合同效力。
        </p>
        <p>
          本协议内容包括本协议正文、365编辑器已经发布或将来可能发布的与365编辑器会员服务相关的各类规则。所有规则为本协议不可分割的组成部分，与本协议具有同等法律效力。一旦相关内容发生变动，365编辑器将会通过站内信等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。
        </p>
        <p class='p_title'>一、定义</p>
        <p>1.365编辑器VIP会员：指注册用户在签署本协议并根据365编辑器公布的收费标准支付相应的费用后获取的特殊资格，具体资格名称以365编辑器公示的名称为准。以下简称“会员”。</p>
        <p>2.会员服务：指会员所享有根据选择购买的会员选项所享有的特殊服务，具体以365编辑器公布的服务内容为准。</p>
        <p class='p_title'>二、 使用规范</p>
        <p>
          1.您申请开通会员服务时，需要提交您本人的手机号码、邮箱及第三方支付账户等个人资料，并保证您提供的个人资料真实、准确、完整、合法有效。如您提供的资料不合法、不真实、不准确、不详尽，由此引起的损失及法律责任将由您自行承担，给365编辑器造成损失的，365编辑器保留要求您赔偿的权利。
        </p>
        <p>
          2.365编辑器有权单方对其提供的虚拟产品(包括但不限于VIP会员)予以定价，并有权根据自身运营需要予以变更，且365编辑器在此获得您的授权可以根据平台统一调整比例调整您已经获取的虚拟产品;基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金亦不得用于商业领域如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，365编辑器有权中止或终止对您的服务。
        </p>
        <p>3.会员服务有效期根据您选择购买的会员选项的具体时长为准，自您成功支付会员服务费用之日起计算。每一个收费项目的服务周期以页面标注期限为准。如您未在有效时间内使用已购买的服务，视为您已使用，365编辑器概不退款。
          若您希望在有效期届满后继续享受会员服务，则需要重新购买。</p>
        <p>4.365编辑器会员周期说明：1个月会员=30天、季享会员=90天、年度会员=360天，半年会员=180天。</p>
        <p>
          5.在使用会员服务过程中，您应当是具备与您行为相适应的民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请务必在您的监护人的帮助下共同完成，若因此使得365编辑器或任何第三方权益受损，您及您的监护人将承担相应的责任。
        </p>
        <p>6.您知悉并同意，365编辑器有权通过站内信、邮件、短信或电话等形式， 向您发送会员活动相关信息。</p>
        <p>
          7.您确认会员服务仅限您本人使用，同时，您保证您将合理使用会员服务，不利用会员服务非法获利，不以任何形式转让您所享有的会员服务，不以任何形式将您所享有的会员服务借给他人使用，如365编辑器有合理理由怀疑您存在不当使用会员服务时，365编辑器有权取消您的会员资格且不退还您支付的会员服务费用，因此产生的相关责任及损失均由您自行承担，给365编辑器造成损失的，365编辑器保留向您追偿的权利。
        </p>
        <p>8.365编辑器网保留在法律法规允许的范围内自行决定是否接受您的会员申请、调整会员服务内容、取消会员资格等相关权利。</p>
        <p>9.您理解并保证，您在使用会员服务过程中遵守诚实信用原则。如365编辑器发现或有合理理甶怀疑您存在以下任一情形的：
          <br>(a) 通过任何不当手段或以违反诚实信用原则的方式开通会员服务的，包括但不限于通过恶意软件绕过365编辑器设定的正常流程开通会员服务；
          <br>(b) 您提供的资料不合法、不真实，包括但不限于盗用他人信息；
          <br>(c) 您通过365编辑器会员专区购买的商品用于法律法规所禁止的目的的；
          <br>(d) 365编辑器有合理理由怀疑您存在违反诚实信用原则的其他行为。 则365编辑器有权拒绝您的会员服务开通需求；若已开通的，365编辑器有权单方面取消您的会员资格且不退还您支付的会员服务费用。
        </p>
        <p>10.您理解并同意365编辑器有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限、收费模式等等。</p>
        <p class='p_title'>三、账号独立</p>
        <p>1.同一主体拥有的QQ帐号、微信帐号、手机账号及微博账号，在进行365编辑器VIP登录时，将被识别为四个独立的帐号；（使用某一个平台注册的账号并绑定其他平台账号的除外）</p>
        <p>2.同一主体拥有多个QQ帐号并进行365编辑器VIP会员登录的，每一个QQ帐号将被识别为一个独立的帐号；</p>
        <p>3.同一主体拥有多个微信帐号并进行365编辑器VIP会员登录的，每一个微信帐号将被识别为一个独立的帐号；</p>
        <p>4. 同一主体拥有多个微博帐号并进行365编辑器VIP会员登录的，每一个微博帐号将被识别为一个独立的帐号；</p>
        <p>5.每个帐号之间的充值记录、VIP权限无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等帐号是否由同一使用人拥有，请您在登录/充值/参加活动时注意区分，避免造成损失。由此造成的损失由VIP会员自行承担。</p>
        <p class='p_title'>四、会员服务售后</p>
        <p>1.您知悉并确认，开通会员服务后，若您中途主动取消服务或终止资格或被365编辑器根据《365编辑器VIP服务协议》、本协议及相关规则注销账号、终止会员资格的，您已支付的会员服务费用将不予退还。</p>
        <p>2.如您有其他与会员服务售后相关的问题，可以通过365编辑器公布的联系方式联系客服进行反馈。</p>
        <p>3.正在365编辑器内进行的平台交易、使用等，365编辑器将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。</p>
        <p class='p_title'>五、其他约定</p>
        <p>1.通知：所有发给您的通知都可通过站内信、电子邮件、常规的信件或在网站显著位置公告的方式进行传送。</p>
        <p>2.本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
        <p>3.如使用会员服务过程中出现纠纷，您与365编辑器应友好协商解决，本协议最终解释权归365编辑器所有。</p>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import { VipApi } from "@/config/axios/api";
import { mapState } from "vuex";
export default {
  name: "Pay",
  props: ["payInfo"],
  data () {
    return {
      payCode: "", //二维码
      aliHtmlData: "",   //支付宝的支付码
      payTimer: null,
      showCodeMsg: false,  //二维码过期
      payTimeNum: 300,  //过期时间
      vipData: [],   //会员列表
      checkData: {},  //选中的会员
      tabFalg: false,   //防抖变量
      protocolVisible: false,   //会员协议
    };
  },
  created () {
    this.checkData = this.payInfo.data || {};
    this.getViplist();
  },
  computed: {
    ...mapState(['loginInfo']),
  },
  mounted () {

  },
  destroyed () { },
  methods: {
    // 是否可购买
    canBuy (item) {
      return (this.loginInfo.is_sc_vip == 1 && this.loginInfo.sc_vip_level == 3 && item.level != 3) || (this.loginInfo.is_sc_vip == 1 && this.loginInfo.sc_vip_level == 2 && item.level == 1)
    },
    // 获取vip列表
    getViplist () {
      VipApi.getVipList().then((res) => {
        if (res.status == 1) {
          this.vipData = res.data.reverse();
          if (!this.checkData.id) {
            this.checkData = this.vipData[0];
          }
          if (!this.checkData.order_id) {
            this.buyFun(this.checkData);
          } else {
            this.getPayCode();
          }
        }
      });
    },

    closePay () {
      clearTimeout(this.resultTimer);
      this.$emit("close");
    },
    // 下订单
    buyFun (item) {
      if (this.tabFalg) {
        return false;
      }
      this.tabFalg = true;
      let parm = {
        package_id: item.id,
        payment_channel: this.payInfo.payment_channel
      };
      VipApi.generateOrder(parm).then((res) => {
        if (res.status == 1) {
          this.checkData = item;
          setTimeout(() => {
            this.tabFalg = false;
          }, 500);
          this.payInfo.out_no = res.data.out_no;
          this.getPayCode();
        } else {
          this.tabFalg = false;
        }
      });
    },
    // 获取支付二维码
    getPayCode () {
      this.showCodeMsg = false;
      VipApi.wechatPay({ order_id: this.payInfo.out_no }).then((res) => {
        if (res.status == 1) {
          var QRCode = require("qrcode");
          var _this = this;
          QRCode.toDataURL(
            res.data.wx_pay_url,
            { margin: 1 },
            function (err, url) {
              _this.payCode = url;
            }
          );
          this.buildCode();
        }
      });
      VipApi.aliPay({ order_id: this.payInfo.out_no }).then((res) => {
        if (res.status == 1) {
          this.buildCode();
          this.aliHtmlData = res.data.ali_param;
        }
      });
    },
    buildCode () {
      clearInterval(this.payTimer);
      this.payTimeNum = 300;
      this.payTimer = setInterval(() => {
        this.payTimeNum--;
        if (this.payTimeNum <= 0) {
          this.showCodeMsg = true;
          clearInterval(this.payTimer);
        }
      }, 1000);
      this.getResult();
    },
    // 获取支付结果
    getResult () {
      clearTimeout(this.resultTimer);
      VipApi.orderCheck({ order_id: this.payInfo.out_no }).then((res) => {
        if (res.status == 1) {
          if (res.data.payment_status == 2) {
            this.$message.success('支付成功');
            clearTimeout(this.resultTimer);
            this.$emit('close', true)
          } else {
            if (this.payInfo.visible && !this.showCodeMsg) {
              this.resultTimer = setTimeout(() => {
                this.getResult();
              }, 2000);
            }
          }
        } else {
          clearTimeout(this.resultTimer);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  .pay-inner {
    width: 896px;
    height: 670px;
    background: #ffffff;
    padding: 32px 48px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    .banquan-close {
      background: none;
      color: rgba(0, 0, 0, 0.5);
      right: 25px;
      top: 26px;
    }

    .payheader {
      .icon {
        position: absolute;
        @include flex(center, '');

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 10px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;
          margin-right: 10px;
        }

        .vip {
          height: 16px;
          background: linear-gradient(135deg, #B77100 0%, #F2BE27 49%, #D68800 100%);
          border-radius: 2px;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 16px;
          text-align: center;

          span {
            transform: scale(0.8);
            display: block;
          }
        }
      }

      .title {
        text-align: center;

        p {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1D2020;
          line-height: 40px;
        }

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;
        }
      }
    }



    .top-box {
      width: 100%;
      height: 238px;
      margin-top: 24px;
      margin-bottom: 28px;

      .payContent-list {

        .payContent-card {
          @include flex(center, space-between);

          .payContent-card-price {
            text-align: center;
            cursor: pointer;
            width: 248px;
            height: 238px;
            padding: 0 20px;
            background: #FFFFFF;
            border-radius: 12px;
            border: 1px solid #DEDEDD;
            position: relative;

            &:hover {
              border: 1px solid #E25246;
            }

            &.not {
              cursor: no-drop;
              opacity: 0.5;

              &:hover {
                border: 1px solid #DEDEDD;
              }
            }

            .jb-box {
              position: absolute;
              top: -14px;
              left: -1px;
              width: 90px;
              height: 25px;
              background: #E25246;
              border-radius: 10px 0px 10px 0px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 25px;
              text-align: center;
            }

            h5 {
              @include flex(center, center);
              margin-top: 28px;
              margin-bottom: 7px;
              text-align: center;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #1D2020;
              line-height: 30px;

              img {
                width: 37px;
                margin-right: 3px;
                height: 36px;
              }
            }

            .price {
              width: 100%;
              text-align: center;
              font-size: 40px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #E25246;
              line-height: 56px;

              em {
                font-style: normal;
                font-size: 14px;
                line-height: 20px;
              }

              p {
                display: inline;
                font-size: 14px;
                color: #1D2020;
                line-height: 20px;
              }

            }

            .origin-price {
              border-bottom: 1px dashed #DEDEDD;
              padding-bottom: 10px;
              margin-bottom: 10px;
              color: #B7B7B7;
              font-size: 12px;
              text-decoration: line-through;
            }

            .desc {
              margin-bottom: 8px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #1D2020;
              line-height: 20px;
            }

            .jiaob {
              display: none;
              position: absolute;
              bottom: 0;
              right: 0;
              width: 34px;
              height: 34px;
              overflow: hidden;
              border-bottom-right-radius: 4px;

              .icon-duigou {
                font-size: 12px;
                color: #fff;
                position: absolute;
                left: 19px;
                top: 17px;
                z-index: 9;
              }

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                border-bottom: 34px solid #E25246;
                border-left: 34px solid transparent;
              }
            }
          }

          .active {
            border: 2px solid #E25246;

            &:hover {
              border: 2px solid #E25246;
            }

            .jb-box {
              left: -2px;
            }

            .jiaob {
              display: block;
            }
          }
        }
      }
    }

    .bott-box {
      overflow: hidden;
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #DEDEDD;
      padding: 17px 24px;

      .pay-code {
        position: relative;

        div {
          width: 100px;
          height: 101px;
          border: 1px solid #EAEAEA;
        }

        margin-top: 6px;
        float: left;

        img {
          width: 100%;
          height: 100%;
        }

        iframe {
          width: 100px;
          height: 101px;
          padding: 4px 0 0 4px;
          border: 1px solid #EAEAEA;
          box-sizing: border-box;
          overflow: hidden;
          float: left;
          margin-bottom: 2px;
        }

        .code-msg {
          position: absolute;
          top: 1px;
          right: 1px;
          width: 100px;
          height: 101px;
          background: rgba(0, 0, 0, 0.5);
          text-align: center;
          font-size: 14px;
          color: #fff;
          border: none;
          padding-top: 30px;
          cursor: pointer;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 17px;
          margin-top: 3px;
          text-align: center;
        }
      }

      .pay-info {
        float: left;
        margin-left: 36px;

        .price {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;

          span {
            font-size: 28px;
            margin-left: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #E4201F;
            line-height: 40px;
            letter-spacing: 1px;
          }
        }

        .type {
          @include flex(center, '');
          margin: 12px 0 16px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;

          img {
            margin-right: 8px;
            width: 22px;
            height: 22px;
          }

        }

        .xianyi {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;

          a {
            color: $activeColor;
          }
        }
      }
    }

    .paytip {
      width: 100%;
      height: 40px;
      margin: 20px 0 14px;
      padding: 0 24px;
      background: rgba(29, 32, 32, 0.04);
      @include flex(center, space-between);

      p {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2020;

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #E25246;
        }
      }

      div {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2020;

        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          margin-right: 24px;

          &:last-of-type {
            margin-right: 0;
          }

          &:first-of-type {
            margin-left: 12px;
          }
        }
      }
    }

    .tip {
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D2020;
      line-height: 20px;
    }
  }

}

// 会员协议
.dialog-vip-protocol {
  position: fixed;

  /deep/ .el-dialog__body {
    padding: 0 !important;
  }

  /deep/ .el-dialog__header {
    border-bottom: none;
    min-height: 55px;
    background: rgba(242, 245, 247, 1);
  }

  /deep/ .el-scrollbar__wrap {
    width: 100%;
    padding: 30px 24px 0;
    box-sizing: border-box;
    height: calc(70vh);

    p {
      margin-bottom: 24px;
      color: #36485c;

      em {
        font-style: normal;
        color: #FEBE49;
        margin: 0 4px;
      }
    }
  }
}
</style>

import store from "@/store/index";
import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Index",
    activiy: true,
    meta: {
      title:
        "365版权图库，专注原创商用设计图片视频下载，会员免费设计素材模板独家图库",
      headerOpacity: false, // 导航是否 取消透明
    },
    component: () => import("../pages/index/index.vue"),
  },

  {
    path: "/register",
    name: "Register",
    meta: {
      headerOpacity: true,
    },
    component: () => import("../pages/common/register.vue"), //
  },
  {
    path: "/forget",
    name: "forgetPassword",
    meta: {
      headerOpacity: true,
    },
    component: () => import("../pages/common/forgetPassword.vue"), //
  },
  {
    path: "/video",
    name: "VideoSearchPage",
    activiy: true,
    meta: {
      headerOpacity: true,
    },
    component: () => import("../pages/video/index.vue"), //
  },
  {
    path: "/hotmore/:id",
    name: "HotMore",
    activiy: true,
    meta: {
      title: "热门图集",
      headerOpacity: true,
    },
    component: () => import("../pages/more/hotmore.vue"), //查看更多热门图集  图集系列
  },
  {
    path: "/hotmoreVideo/:id",
    name: "HotMoreVideo",
    activiy: true,
    meta: {
      title: "热门图集",
      headerOpacity: true,
    },
    component: () => import("../pages/more/hotmoreVideo.vue"), //查看更多热门图集  图集系列
  },
  {
    path: "/search",
    name: "SearchPage",
    activiy: true,
    meta: {
      title: "高清图片大全-配图素材-高清摄影图免费下载-365版权图库",
      headerOpacity: true,
      index: 0,
    },
    component: () => import("../pages/search/index.vue"), //搜索结果
  },
  {
    path: "/vip",
    name: "Vip",
    meta: {
      title: "个人VIP",
      headerOpacity: true,
    },
    component: () => import("../pages/vip/vip.vue"), //个人vip
  },
  {
    path: "/details",
    name: "Details",
    activiy: true,
    meta: {
      // title: '图片详情',
      headerOpacity: true,
    },
    component: () => import("../pages/details/details.vue"),
  },
  {
    path: "/personal",
    name: "Personal",
    meta: {
      title: "个人中心",
      headerOpacity: true,
      hideFooter: true,
    },
    component: () => import("../pages/personal/personal.vue"), //个人中心
    children: [
      {
        path: "",
        meta: {
          title: "收藏夹",
          headerOpacity: true,
          hideFooter: true,
          index: 0,
        },
        component: () => import("../pages/personal/directory.vue"),
        children: [
          {
            path: "",
            name: "collectPage",
            meta: {
              title: "图片收藏夹",
              headerOpacity: true,
              hideFooter: true,
              index: 0,
              name: "收藏夹",
            },
            component: () => import("../pages/personal/directoryIndex.vue"),
          },
          {
            path: "detail/:id",
            meta: {
              title: "图片收藏夹详情",
              headerOpacity: true,
              hideFooter: true,
              index: 0,
              name: "收藏夹",
            },
            component: () => import("../pages/personal/directoryDetail.vue"),
          },
        ],
      },
      {
        path: "order", //订单
        meta: {
          title: "订单中心",
          headerOpacity: true,
          hideFooter: true,
          index: 1,
        },
        component: () => import("../pages/personal/order.vue"),
      },
      {
        path: "record", //下载记录
        meta: {
          title: "下载记录",
          headerOpacity: true,
          hideFooter: true,
          index: 2,
        },
        component: () => import("../pages/personal/record.vue"),
      },
      {
        path: "info", //账户信息
        meta: {
          title: "账户信息",
          headerOpacity: true,
          hideFooter: true,
          index: 5,
        },
        component: () => import("../pages/personal/info.vue"),
      },
      {
        path: "invoice", //申请发票
        meta: {
          title: "申请发票",
          headerOpacity: true,
          hideFooter: true,
          index: 6,
        },
        component: () => import("../pages/personal/invoice.vue"),
      },
    ],
  },
  {
    path: "*",
    meta: {
      headerOpacity: true,
      hideFooter: true,
      hideHeader: true,
    },
    component: () => import("../components/404.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (Vue.prototype.staticFunc.isLogin()) {
    store.dispatch("loginInfo");
    next();
  } else if (to.path.indexOf("/personal") >= 0) {
    location.href = "/";
  } else {
    next();
  }
});

router.afterEach(() => {});
export default router;

/**
 * statsMark - 埋点字段
 * projectArea - 事件页面区域
 * eventArea - 事件id
 * eventType - 事件类型 1跳转 2 .浏览 非跳转ajax 3.点击事件 4.滚动下拉上拉事件 5.填写表单事件
 * eventName - 事件别名
 */

let stats_data = [

  {
    statsMark: "热门推荐",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1267',
    eventName: 'hot_recommend',
  },{
    statsMark: "图片推荐",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1268',
    eventName: 'img_recommend',
  },{
    statsMark: "视频推荐",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1269',
    eventName: 'video_recommend',
  },{
    statsMark: "搜索点击",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1270',
    eventName: 'search_click',
  },{
    statsMark: "图片下载原图",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1271',
    eventName: 'img_download_origin',
  },{
    statsMark: "图片下载样图",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1272',
    eventName: 'img_download_sample',
  },{
    statsMark: "视频下载",
    projectPage: '61',
    projectArea: "137",
    eventArea: '137',
    eventType: '3',
    eventId: '1273',
    eventName: 'video_download',
  },

]

export default stats_data;


<template>
  <div>
    <ul>
      <li @click="resource_loc_click('首页')">
        <router-link to="/">首页</router-link>
      </li>
      <li @click="resource_loc_click('图片')">
        <router-link to="/search" :class="seachTypeData == '图片' ? 'router-link-exact-active' : ''">图片</router-link>
      </li>
      <li @click="resource_loc_click('视频')">
        <router-link to="/video" :class="seachTypeData == '视频' ? 'router-link-exact-active' : ''">视频</router-link>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      seachTypeData: "",
    };
  },
  created () {
    this.active(this.$route.name);
  },
  methods: {
    active (name) {
      if (name == "SearchPage") {
        this.seachTypeData = "图片";
      } else if (name == "MusicSearchPage") {
        this.seachTypeData = "音乐";
      } else if (name == "DesignSearchPage") {
        this.seachTypeData = "设计";
      } else if (name == "VideoSearchPage") {
        this.seachTypeData = "视频";
      } else if (name == "ExemptPage") {
        this.seachTypeData = "免抠元素";
      } else if (name == "PptPage") {
        this.seachTypeData = "PPT模板";
      }
    },
    resource_loc_click () {
      this.$store.commit("setHeaderShow", false);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: none;
  position: absolute;
  left: -12px;
  top: 60px;
  width: 115px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 10px 0;

  li {
    width: 102px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
    margin: 0 auto;
    line-height: 40px;

    a {
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0a051b;
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }

    /* .router-link-exact-active::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 6px;
      left: 20px;
      width: 59%;
      height: 2px;
      background: #000;
      border-radius: 2px;
    } */
    .router-link-exact-active {
      background: #f8f8f8;
      color: $activeColor;

      a {
        color: $activeColor;
      }
    }

    &:hover {
      background: #f8f8f8;

      a {
        color: $activeColor;
      }
    }
  }
}
</style>

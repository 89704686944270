import "@/assets/css/style/theme/index.css";
import "@/assets/icon/font/iconfont.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import MetaInfo from "vue-meta-info";
import "element-ui/lib/theme-chalk/index.css";

import "swiper/dist/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

Vue.use(ElementUI);
Vue.use(MetaInfo);
import staticFunc from "@/config/static/func";
import Statistics from '@/config/static/statistics'
import statsdata from '@/config/static/statsdata'

Vue.prototype.staticFunc = staticFunc;
Vue.prototype.Statistics = Statistics;
Vue.prototype.statsdata = statsdata;

// 编辑器请求地址
Vue.prototype.editorUrl = process.env.VUE_APP_BaseUrl.slice(0, -1);
Vue.prototype.getImgCodeUrl = "/sc/api/captcha/code";
// 错误提示信息
Vue.prototype.catchMessage = function (info) {
  var error_type = typeof info;
  var msg;
  if (error_type == "string") {
    msg = info;
  }
  if (error_type == "object") {
    if (info.data) {
      msg = info.data.msg;
      if (info.data.code) {
        msg += `(${info.data.code})`;
      }
    } else {
      msg = info.msg || info.error || info.message;
    }
  }
  Vue.prototype.$message.error(msg);
};

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  store.commit("watchPageScroll", false);
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
// 注册全局过滤器 格式化大小
Vue.filter("fmtSize", (value) => {
  let units = ["B", "KB", "MB", "GB", "TB", "PB"];
  let i = 0;
  for (i; value >= 1000 && i < units.length; i++) {
    value /= 1000;
  }
  return (
    (value.toFixed(2).split(".")[1] == "00" ? value : value.toFixed(2)) +
    units[i]
  );
});
new Vue({
  router,
  store,
  mounted() {
    document.dispatchEvent(new Event("custom-render-trigger"));
    Statistics.init(statsdata);
  },
  render: (h) => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this; //安装全局事件总线，$bus就是当前应用的vm
  },
}).$mount("#app");
